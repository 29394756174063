.App {
  text-align: center;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.input-container {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="range"] {
  width: 10%;
  margin-bottom: 10px;
}

button {
  margin-top: 50px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

button:hover {
  background-color: #0056b3;
}

.button-disabled {
  background-color: #ccc;
  color: #999;
  cursor: not-allowed;
  opacity: 0.5; /* optional */
}

textarea,
input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
  resize: none;
  font-size: 14px;
}

textarea {
  height: 100px;
}

.input-container {
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

label {
  margin-right: 10px;
  min-width: 80px;
}

/* Example Styles for React Tags*/
.app {
  padding: 40px;
  text-align: center;
}

/* Example Styles for React Tags*/

.container {
  margin: auto;
  width: 50%;
}

.loading-indicator {
  position: relative;
}

.loading-indicator:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top-color: #fff;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}